/* eslint-disable no-new */

import '@babel/polyfill'
import Vue from 'vue'

import VueResource from 'vue-resource'
import VueRouter from 'vue-router'

import VueSweetalert2 from 'vue-sweetalert2'
import '@sweetalert2/theme-material-ui/material-ui.css'

import router from 'router'

Vue.use(VueResource)
Vue.use(VueRouter)
Vue.use(VueSweetalert2, {
  confirmButtonText: '確定',
  denyButtonText: '取消',
  cancelButtonText: '取消',
})
Vue.use(require('@/lib/viewport-condition.js').default)
Vue.use(require('lib/vue/plugins/deviceView.js').default)
Vue.use(require('lib/vue/plugins/loadingState.js').default)
Vue.use(require('lib/vue/plugins/filters.js').default)
Vue.http.options.root = process.env.VUE_APP_SITE_ROOT

const state = Vue.observable({
  user: null,
})

Vue.mixin({
  computed: {
    $user() { return state.user }
  }
})

// 定義入口
const components = {

  ModuleSiteFooter: () => import(/* webpackChunkName: "partial-site" */ 'modules/site/Footer.vue'),
  ModuleSiteHeader: () => import(/* webpackChunkName: "partial-site" */ 'modules/site/Header.vue'),
  PageHome2020: () => import(/* webpackChunkName: "page-home" */ 'pages/home/2020.vue'),

  PageManuals: () => import(/* webpackChunkName: "page-manuals" */ 'pages/manuals.vue'),

  // Partial
  PagePartialsNewsmenu: () => import(/* webpackChunkName: "partial-news" */ 'pages/partials/newsmenu.vue'),
  PagePartialsLocationsmenu: () => import(/* webpackChunkName: "partial-news" */ 'pages/partials/locationsmenu.vue'),

  // Events
  PageEventExhibitionKrv: () => import(/* webpackChunkName: "page-event-exhibition-krv" */ 'pages/event/exhibition/Krv.vue'),
  PageEventExhibitionNiceXl: () => import(/* webpackChunkName: "page-event-exhibition-nice" */ 'pages/event/exhibition/NiceXL.vue'),
  PageEventKrvTestrideIndex: () => import(/* webpackChunkName: "event-krv" */ 'pages/event/krv_testride/index.vue'),
  PageEvent2022kymcocare: () => import(/* webpackChunkName: "event-2022kymcocare" */ 'pages/event/2022kymcocare/index.vue'),
  // Deprecated
  // PageIonexSubsidies: () => import(/* webpackChunkName: "page-ionex" */ 'pages/ionex/Subsidies.vue'),
}

const ready = function (fn) {
  if (document.readyState === 'complete') {
    fn()
  } else if (document.addEventListener) {
    window.addEventListener('load', fn, false)
  } else {
    window.attachEvent('onload', fn)
  }
}

ready(function () {
  // Mount app

  $.request('onGetUser', {
    success: (rawSource) => {
      state.user = rawSource.user
    },
  })

  const PageLauncher = Vue.extend({
    name: 'PageLauncher',
    components,
    router,
  })

  // Mount modules
  document.querySelectorAll('.module-launcher:not(.mounted)').forEach(el => {
    const app = new Vue({
      el,
      components,
      updated() {
        this.$el.classList.add('mounted')
      },
    })
    
    const pageName = $('body').attr('data-pageName')
    if(pageName != "車款總覽"){
      // 車款總覽元件較多，所以寫在元件裡modules/catalogue/menu.vue
      $('#pageLoading').fadeOut()
    }

  })

  // Mount Page after Modules
  const elAppLauncher = document.querySelector('#page-app-launcher:not(.mounted)')
  if (elAppLauncher) {
    elAppLauncher.classList.add('mounted')
    elAppLauncher && new PageLauncher({
      el: elAppLauncher,
    })
  }
})
